import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK } from '@web3auth/base'
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider'
import { SDK_MODE, Web3Auth } from '@web3auth/single-factor-auth'
import { jwtDecode } from 'jwt-decode'

const chainConfig = {
  chainId: '0x1',
  displayName: 'Ethereum Mainnet',
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  tickerName: 'Ethereum',
  ticker: 'ETH',
  decimals: 18,
  rpcTarget: 'https://rpc.ankr.com/eth',
  blockExplorerUrl: 'https://etherscan.io',
  logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png',
}

const privateKeyProvider = new EthereumPrivateKeyProvider({
  config: { chainConfig },
})

const web3auth = new Web3Auth({
  mode: SDK_MODE.WEB,
  clientId: process.env.NEXT_PUBLIC_WEB_3_AUTH_CLIENT_ID,
  web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_DEVNET,
  privateKeyProvider,
})

export const initWeb3Auth = async (idToken: string) => {
  if (web3auth.status === 'not_ready') {
    await web3auth.init()
  }

  const { sub } = jwtDecode(idToken)

  if (web3auth.status !== 'connected') {
    await web3auth.connect({
      verifier: process.env.NEXT_PUBLIC_WEB_3_AUTH_VERIFIER,
      verifierId: sub || '',
      idToken,
    })
  }

  return web3auth
}

export const disconnectWeb3Auth = async () => {
  if (!web3auth) {
    return
  }
  if (web3auth.status === 'connected') {
    await web3auth.logout()
  }
}

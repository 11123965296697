import { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { CommonButton } from 'components/common-button'

interface CopyLinkBoxProps {
  link: string
}

const CopyLinkBox = ({ link }: CopyLinkBoxProps) => {
  const { t } = useTranslation('common')
  const [isLinkCopied, setIsLinkCopied] = useState(false)

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(link)
      if (!isLinkCopied) {
        setIsLinkCopied(true)
        setTimeout(() => setIsLinkCopied(false), 2000)
      }
    } catch (error) {
      // this should never happen, but just in case
      console.error('Failed to copy the link', error)
    }
  }

  return (
    <div className="flex w-full flex-col gap-2">
      <p className="text-sm font-bold text-white">{t('buttons.copyLinkLabel')}</p>
      <button
        className="relative flex w-full items-center justify-end gap-4 rounded-3xl bg-black-transparent30-solid py-1 pl-5 pr-1"
        // @dr NOTE: This is for UX interactivity - user can also copy the link by just clicking on the box with a long link
        // it's also the reason why the <CommonButton> does not need any onClick event handler
        onClick={() => {
          void copyLink()
        }}
        type="button"
      >
        <p className="absolute left-[1.25rem] w-[calc(100%-9rem)] select-none truncate text-sm">
          {link}
        </p>
        <CommonButton type="button" className="align-bottom">
          {isLinkCopied ? t('buttons.copyLinkSuccess') : t('buttons.copyLink')}
        </CommonButton>
      </button>
    </div>
  )
}

export default CopyLinkBox

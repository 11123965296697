import { FC } from 'react'
import { useAvailableInvitesCount } from 'hooks/useAvailableInvitesCount'
import { useMe } from 'hooks/useMe'
import { useTranslation } from 'next-i18next'
import CopyLinkBox from 'components/copy-link-box/copy-link-box'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog'
import InvitesLeftIndicator from './invites-left-indicator'

interface SettingsDialogToggleProps {
  toggle: FC<{ invitesCount?: number }>
}

export const InvitationDialogToggle = ({ toggle }: SettingsDialogToggleProps) => {
  const { t } = useTranslation('common')
  const { me } = useMe()

  const { data: invitesCount } = useAvailableInvitesCount()

  const inviteLink = `${process.env.NEXT_PUBLIC_WEB_URL}/invite/${me?.userName}`

  return (
    <Dialog>
      <DialogTrigger>{toggle({ invitesCount })}</DialogTrigger>
      <DialogContent className="w-fit p-10 font-madefor backdrop-blur-sm">
        <DialogHeader>
          <DialogTitle className="font-light text-white">
            {t('components.invitation.title')}
          </DialogTitle>
        </DialogHeader>
        <div className="mt-[-8px] flex max-w-[300px] flex-col gap-5 font-light text-white">
          <div>
            <p className="text-sm text-white-transparent60">
              {t('components.invitation.description')}
            </p>
          </div>
          <CopyLinkBox link={inviteLink} />
          {invitesCount && <InvitesLeftIndicator invitesLeft={invitesCount} />}
        </div>
      </DialogContent>
    </Dialog>
  )
}
